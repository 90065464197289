import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import * as CONST from "../../../components/const";

const kv = "../../../images/employment/young/kv.jpg";
const content1 = "../../../images/business/img1.png";
const content2 = "../../../images/business/img2.png";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

	return (
		<Layout>
			<SEO
				description="ALPHA WAVEの運用保守サービスの詳細です。サービスの概要、ネットワーク・システム・業務運用管理、システム保守、費用例をご覧ください。"
				title="Solution"
			/>
			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
					<div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}>
						<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Business content <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>事業内容</span></p>
					</div>
					<div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
						<div>
							<h1 className={CONST.H1_CLASS}>実績</h1>
							<p>ソリューション提供の実績をご紹介します。</p>
						</div>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={kv} />
					<div className={CONST.KEY_VISUAL_RIGHT_HALF_MESSAGE_AREA_CLASS}>
						<div>
							<p>ソリューション提供</p>
						</div>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<h4 className={CONST.H4_CLASS}>サービスの概要</h4>
						<p></p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={content1} />
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={content2} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<div>
						<h4 className={CONST.H4_CLASS}>詳細</h4>
						<h4 className={CONST.H4_CLASS}>ネットワーク管理</h4>
						<h4 className={CONST.H4_CLASS}>システム管理</h4>
						<h4 className={CONST.H4_CLASS}>業務運用管理</h4>
						<h4 className={CONST.H4_CLASS}>システム保守</h4>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<h4 className={CONST.H4_CLASS}>費用例</h4>
						<p></p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={content1} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-full xl:w-1/12 bg-white py-8 pl-12">
					<p className="font-bold">事例</p>
				</div>
				<div className="w-full xl:w-11/12 bg-white px-12">
					<Link to="use_conservative/keitahaginiwa" className="p-1">
						<p className="pb-1 border-b border-dashed">
							<span className="pr-10">2020.4.1</span>
							<span>株式会社萩庭桂太写真事務所様より~ご依頼をいただきました。</span>
						</p>
					</Link>
					<Link to="use_conservative/m-tadao" className="p-1">
						<p className="pb-1 border-b border-dashed">
							<span className="pr-10">2020.4.1</span>
							<span>松田忠雄様より～ご依頼をいただきました。</span>
						</p>
					</Link>
				</div>
			</section>
		</Layout>
	);
}

export default IndexPage;
